import { submitDesign } from "../designSubmission";
import Swal from "sweetalert2";
import React, { useState, useEffect } from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormControlLabel,
  Checkbox,
} from "@mui/material";

const staffAPIUrl = process.env.REACT_APP_STAFF_API_CALL;
const catAPIUrl = process.env.REACT_APP_CATEGORIES_API_CALL;
const imgBBApiKeyENV = process.env.REACT_APP_IMGBB_API_KEY;
const webdevWebhook = process.env.REACT_APP_WEBDEV_CHANNEL_WEBHOOK;
const uploadWarningWebhookUrl = process.env.REACT_APP_UPLOAD_WARNING_WEBHOOK;

const DesignForm = () => {
  const [designers, setDesigners] = useState([]);
  const [categories, setCategories] = useState([]);
  const [designName, setDesignName] = useState("");
  const [designerId, setDesignerId] = useState("");
  const [categoryId, setCategoryId] = useState("");
  const [file, setFile] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState("");
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    const fetchAPI = async (url) => {
      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return await response.json();
      } catch (error) {
        console.error(`Error fetching data from ${url}:`, error);
        return [];
      }
    };

    fetchAPI(staffAPIUrl).then((data) => setDesigners(data));
    fetchAPI(catAPIUrl).then((data) => setCategories(data));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!file) {
      Swal.fire({
        icon: "error",
        title: "Are you okay bro?",
        text: "You haven't selected an image to upload.",
        footer: `Wasn't that kind of the point?`,
      });
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    formData.append("name", designName);

    try {
      const response = await fetch(
        `https://api.imgbb.com/1/upload?key=${imgBBApiKeyENV}`,
        {
          method: "POST",
          body: formData,
        }
      );
      const imgbbData = await response.json();

      if (response.ok && imgbbData.data && imgbbData.data.url) {
        const imageUrl = imgbbData.data.url;
        const designerName = designers.find((d) => d.id === designerId)?.name;
        const category = categories.find(
          (c) => c.id === parseInt(categoryId)
        )?.category_name;
        const webhookUrl = categories.find(
          (c) => c.id === parseInt(categoryId)
        )?.webhook_url;

        if (checked) {
          await sendWebhook(uploadWarningWebhookUrl, {
            content: `<@&1081097282444861470> <@&1194800822001864734> A design has been made by **${designerName}** and payment hasn't been received.`,
            embeds: [
              {
                title: `DESIGNER UNPAID - ${designerName}`,
                description: `:newspaper: **Sleeve Name:** ${designName}\n::file_cabinet:: **Category:** ${category}\n:computer: **ImgBB Link:** ${imageUrl}\n:art: **Sleeve Designer:** ${designerName}\n:notepad_spiral: **Additional Notes:** ${additionalNotes}`,
                color: 3447003,
                image: { url: imageUrl },
              },
            ],
          });
        }

        if (webhookUrl) {
          await sendWebhook(webhookUrl, {
            content: `New design submission: ${designName}`,
            embeds: [
              {
                title: `Design Submitted by ${designerName}`,
                description: `:newspaper: **Sleeve Name:** ${designName}\n::file_cabinet:: **Category:** ${category}\n:computer: **ImgBB Link:** ${imageUrl}\n:art: **Sleeve Designer:** ${designerName}\n:notepad_spiral: **Additional Notes:** ${additionalNotes}`,
                color: 3447003,
                image: { url: imageUrl },
              },
            ],
          });

          await sendWebhook(webdevWebhook, {
            content: `<@&1142217961088766022> :wave: A new sleeve has been uploaded by **${designerName}** to the **${category}** category. \n:white_check_mark: \`Upload to ImgBB Complete\`\n:white_check_mark: \`Submission to Discord Category Complete\`\n**Please React with a :ballot_box_with_check: once uploaded to the website.**`,
            embeds: [
              {
                title: `Design Submitted by ${designerName}`,
                description: `:newspaper: **Sleeve Name:** ${designName}\n::file_cabinet:: **Category:** ${category}\n:computer: **ImgBB Link:** ${imageUrl}\n:art: **Sleeve Designer:** ${designerName}\n:notepad_spiral: **Additional Notes:** ${additionalNotes}`,
                color: 3447003,
                image: { url: imageUrl },
              },
            ],
          });
          submitDesign(
            designerId,
            designName,
            categoryId,
            imageUrl,
            additionalNotes
          );
          /* alert("Design submitted successfully!"); */
          Swal.fire({
            icon: "success",
            title: "Hooray!",
            text: "The design has been submitted successfully!",
            footer: `<a href="${imageUrl}">View the design?</a>`,
          });
        } else {
          throw new Error("Webhook URL not found");
        }
      } else {
        Swal.fire({
          icon: "error",
          title: "Houston, we have a problem",
          text: "The image upload to the host failed.",
          footer: `This error is likely due to the ImgBB API key being invalid or expired. Please contact the developer immediately. This error cannot be resolved without developer intervention.`,
        });
      }
    } catch (error) {
      console.error("Failed to submit design:", error);
      Swal.fire({
        icon: "error",
        title: "Oh no!",
        text: "Something went wrong ...",
        footer: `Please press F12 to view the error in the console and send a screenshot to the developer.`,
      });
    }
  };

  const sendWebhook = async (url, payload) => {
    await fetch(url, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(payload),
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <FormControl fullWidth sx={{ mt: 2 }}>
        <InputLabel id="designer-name-label">Designer Name</InputLabel>
        <Select
          labelId="designer-name-label"
          id="designerName"
          value={designerId}
          label="Designer Name"
          onChange={(e) => setDesignerId(e.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {designers.map((designer) => (
            <MenuItem key={designer.id} value={designer.id}>
              {designer.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        id="designName"
        label="Design Name"
        variant="outlined"
        margin="normal"
        value={designName}
        onChange={(e) => setDesignName(e.target.value)}
      />
      <FormControl fullWidth margin="normal">
        <InputLabel id="design-category-label">Design Category</InputLabel>
        <Select
          labelId="design-category-label"
          id="designCategory"
          value={categoryId}
          label="Design Category"
          onChange={(e) => setCategoryId(e.target.value)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {categories.map((category) => (
            <MenuItem key={category.id} value={category.id}>
              {category.category_name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        fullWidth
        type="file"
        margin="normal"
        onChange={(e) => setFile(e.target.files[0])}
      />
      <TextField
        fullWidth
        margin="normal"
        id="filled-multiline-static"
        label="Additional Notes"
        multiline
        rows={4}
        value={additionalNotes}
        onChange={(e) => setAdditionalNotes(e.target.value)}
        variant="filled"
      />
      <FormControlLabel
        id="designerPaid"
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => setChecked(e.target.checked)}
          />
        }
        label="Designers Only - Check this box if you haven't yet been paid for this design submission - this will send a warning to the staff."
        sx={{ mt: 2 }}
      />
      <Button type="submit" variant="contained" sx={{ mt: 2 }}>
        Submit
      </Button>
    </form>
  );
};

export default DesignForm;

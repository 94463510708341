async function submitDesign(
  designerId,
  designName,
  categoryId,
  imageUrl,
  additionalNotes
) {
  const submissionData = {
    designerId,
    designName,
    categoryId,
    imageUrl,
    additionalNotes,
  };

  try {
    const response = await fetch(
      "https://api.momentz.photography/submit-design",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(submissionData),
      }
    );

    const responseData = await response.json();
    if (!response.ok) {
      throw new Error(
        `Server responded with ${response.status}: ${responseData.message}`
      );
    }
    console.log("Design submitted successfully:", responseData);
  } catch (error) {
    console.error("Failed to submit design:", error);
  }
}

export { submitDesign };

// src/App.js

import React from "react";
import { CssBaseline, Container } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import theme from "./theme";
import DesignFormCard from "./components/DesignFormCard";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <>
        <CssBaseline />
        <Container>
          <DesignFormCard />
        </Container>
      </>
    </ThemeProvider>
  );
}

export default App;

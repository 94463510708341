// src/components/DesignFormCard.js

import React from "react";
import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import DesignForm from "./DesignForm";

const DesignFormCard = () => {
  return (
    <Card
      sx={{
        maxWidth: 600,
        mx: "auto",
        mt: 5,
        bgcolor: "background.paper",
        color: "text.primary",
      }}
    >
      <CardMedia
        component="img"
        height="140"
        image="https://i.ibb.co/sQBth7F/Untitled-1.png"
        alt="Design"
      />
      <CardContent>
        <Typography gutterBottom variant="h5" component="div">
          Momentz | Digital Designer Submissions
        </Typography>
        <Typography variant="body2" color="text.secondary">
          Provide details about the design submission here.
        </Typography>
        <DesignForm />
      </CardContent>
    </Card>
  );
};

export default DesignFormCard;
